import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Snackbar,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { SingleSelectComponent } from "../../Form/SingleSelectComponent";
import { TextFieldComponent } from "../../Form/TextFieldComponent";
import { CauseTaxReceiptDto } from "../../../entities/CauseTaxReceiptDto";
import { CauseLegalEntityCategoryDto } from "../../../entities/CauseLegalEntityCategoryDto";
import { CauseLegalEntitySubcategoryDto } from "../../../entities/CauseLegalEntitySubcategoryDto";
import { CheckboxComponent } from "../../Form/CheckboxComponent";
import {
  getCauseForTaxReceiptData,
  updateCauseForTaxReceiptData,
} from "../../../services/causeService";
import {
  getCauseLegalEnttiyCategories,
  getCauseLegalEnttiySubcategories,
} from "../../../services/causeLegalEntityService";

function TaxReceiptUpdate() {
  const [causes, setCauses] = useState<CauseTaxReceiptDto[]>([]);
  const [causeLegalEntityCategories, setCauseLegalEntityCategories] = useState<
    CauseLegalEntityCategoryDto[]
  >([]);
  const [causeLegalEntitySubcategories, setCauseLegalEntitySubcategories] =
    useState<CauseLegalEntitySubcategoryDto[]>([]);

  const [selectedCauseId, setSelectedCauseId] = useState<string>("");
  const [formValues, setFormValues] = useState<Partial<CauseTaxReceiptDto>>({});
  const [
    selectedCauseLegalEntityCategory,
    setSelectedCauseLegalEntityCategory,
  ] = useState<string>("");
  const [
    selectedCauseLegalEntitySubcategory,
    setSelectedCauseLegalEntitySubcategory,
  ] = useState<string>("");
  const isFormDisabled = false; // Define the variable
  const [isFormInvalid, setIsFormInvalid] = useState<boolean>(false);
  const [errorMessageLoadData, setErrorMessageLoadData] = useState<string>("");
  const [errorMessageSaveData, setErrorMessageSaveData] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const signatoryPositions = [
    { code: "PRESIDENT", value: "President" },
    { code: "TREASURER", value: "Treasurer" },
  ];

  useEffect(() => {
    async function fetchData() {
      setErrorMessageLoadData("");
      try {
        const causeResponse = await getCauseForTaxReceiptData();
        const causeLegalEntityCategoryResponse =
          await getCauseLegalEnttiyCategories();
        const causeLegalEntitySubcategoryResponse =
          await getCauseLegalEnttiySubcategories();
        setCauses(causeResponse);
        setCauseLegalEntityCategories(causeLegalEntityCategoryResponse);
        setCauseLegalEntitySubcategories(causeLegalEntitySubcategoryResponse);
      } catch (error) {
        setErrorMessageLoadData("Could not fetch data, with error: " + error);
      }
    }
    fetchData();
  }, []);

  const handleCauseChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { value: unknown }
    >
  ) => {
    const selectedId = event.target.value as string;
    setSelectedCauseId(selectedId);
    const selectedCause = causes.find((cause) => cause.id === selectedId);
    if (selectedCause) {
      setFormValues({
        ...selectedCause,
        is200Cgi: selectedCause.is200Cgi ?? false,
        is978Cgi: selectedCause.is978Cgi ?? false,
        alsaceMoselle: selectedCause.alsaceMoselle ?? false,
      });
      setSelectedCauseLegalEntityCategory(
        selectedCause.causeLegalEntityCategoryCode
      );
      setSelectedCauseLegalEntitySubcategory(
        selectedCause.causeLegalEntitySubcategoryCode
      );
    }
  };

  const handleCauseCategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = event.target.value as string;
    setSelectedCauseLegalEntityCategory(value);
    setSelectedCauseLegalEntitySubcategory("");
    setFormValues((prevValues) => ({
      ...prevValues,
      causeLegalEntityCategoryCode: value,
      causeLegalEntitySubcategoryCode: "",
    }));
  };

  const handleCauseSubcategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = event.target.value as string;
    setSelectedCauseLegalEntitySubcategory(value);
    setFormValues((prevValues) => ({
      ...prevValues,
      causeLegalEntitySubcategoryCode: value,
    }));
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = event.target as HTMLInputElement;
    const checked = (event.target as HTMLInputElement).checked;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSignatoryPositionChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = event.target.value as string;
    setFormValues((prevValues) => ({
      ...prevValues,
      signatoryPosition: value,
    }));
  };

  const validateForm = useCallback(() => {
    const newErrors: { [key: string]: string } = {};

    const checkRequiredFields = () => {
      if (!formValues.rna && !formValues.siren) {
        newErrors.rna = "Either RNA or SIREN must be filled";
        newErrors.siren = "Either RNA or SIREN must be filled";
      }

      if (!formValues.is200Cgi && !formValues.is978Cgi) {
        newErrors.is200Cgi =
          "At least one of 200 CGI or 978 CGI must be selected";
        newErrors.is978Cgi =
          "At least one of 200 CGI or 978 CGI must be selected";
      }

      if (!formValues.object) {
        newErrors.object = "Object is required";
      }

      if (!formValues.signatoryFirstName) {
        newErrors.signatoryFirstName = "Signatory First Name is required";
      }

      if (!formValues.signatoryLastName) {
        newErrors.signatoryLastName = "Signatory Last Name is required";
      }
    };

    const checkCategorySpecificFields = () => {
      if (
        selectedCauseLegalEntityCategory === "GENERAL_INTEREST" &&
        !selectedCauseLegalEntitySubcategory
      ) {
        newErrors.causeLegalEntitySubcategoryCode = "Subcategory is required";
      }

      if (selectedCauseLegalEntitySubcategory === "PUBLIC_UTILITY") {
        if (!formValues.datePublicInterest) {
          newErrors.datePublicInterest = "Date of Public Interest is required";
        }
        if (!formValues.alsaceMoselle && !formValues.dateOfficialJournal) {
          newErrors.dateOfficialJournal =
            "Date of Official Journal Publication is required";
        }
      }

      if (
        selectedCauseLegalEntityCategory === "GENERAL_INTEREST" &&
        selectedCauseLegalEntitySubcategory === "OTHER" &&
        !formValues.otherSubcategoryValue
      ) {
        newErrors.otherSubcategoryValue = "Other Subcategory Value is required";
      }

      if (
        selectedCauseLegalEntityCategory === "HERITAGE_FND" &&
        !formValues.dateBudgetMinistryAgreement
      ) {
        newErrors.dateBudgetMinistryAgreement =
          "Date of Budget Ministry Agreement is required";
      }

      if (
        selectedCauseLegalEntityCategory === "EU_ENTITY" &&
        !formValues.dateUeAgreement
      ) {
        newErrors.dateUeAgreement = "Date of UE Agreement is required";
      }
    };

    if (
      formValues.signatoryFirstName &&
      formValues.signatoryLastName &&
      formValues.object &&
      formValues.signatoryPosition &&
      formValues.causeLegalEntityCategoryCode
    ) {
      checkRequiredFields();
      checkCategorySpecificFields();
      setErrors(newErrors);
      setIsFormInvalid(Object.keys(newErrors).length > 0);
    } else {
      setIsFormInvalid(true);
    }
  }, [
    formValues.signatoryFirstName,
    formValues.signatoryLastName,
    formValues.object,
    formValues.signatoryPosition,
    formValues.causeLegalEntityCategoryCode,
    formValues.rna,
    formValues.siren,
    formValues.is200Cgi,
    formValues.is978Cgi,
    formValues.datePublicInterest,
    formValues.otherSubcategoryValue,
    formValues.dateBudgetMinistryAgreement,
    formValues.dateUeAgreement,
    formValues.alsaceMoselle,
    formValues.dateOfficialJournal,
    selectedCauseLegalEntityCategory,
    selectedCauseLegalEntitySubcategory,
  ]);

  useEffect(() => {
    validateForm();
  }, [formValues, validateForm]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setErrorMessageSaveData("");
    if (isFormInvalid) {
      setErrorMessageSaveData(
        "The form is invalid. Please check the fields and try again."
      );
      return;
    }

    try {
      await updateCauseForTaxReceiptData(formValues as CauseTaxReceiptDto);
      const updatedCause = await getCauseForTaxReceiptData();
      setCauses(updatedCause);
      setOpenSnackbar(true);
    } catch (error) {
      setErrorMessageSaveData(
        "An error occurred while updating the cause: " +
          error +
          ". Please check data."
      );
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography component="h2" variant="h2">
        Tax Receipt Info Update
      </Typography>
      <form>
        <SingleSelectComponent
          name="cause"
          label="Cause"
          value={selectedCauseId}
          onChange={handleCauseChange}
          options={causes}
          optionLabelKey="name"
          optionValueKey="id"
          disabled={isFormDisabled}
        />

        <FormControl error={!!errorMessageLoadData}>
          {errorMessageLoadData && (
            <FormHelperText>{errorMessageLoadData}</FormHelperText>
          )}
        </FormControl>

        {selectedCauseId && (
          <>
            <Box sx={{ mt: 2 }}>
              <CheckboxComponent
                name="alsaceMoselle"
                label="Cause from Alsace Moselle"
                checked={formValues.alsaceMoselle!}
                onChange={handleInputChange}
              />
            </Box>
            <TextFieldComponent
              name="object"
              label="Object"
              value={formValues.object ?? ""}
              onChange={handleInputChange}
              maxLength={5000}
              multiline
              rows={2}
              error={!!errors.object}
              helperText={errors.object}
              disabled={isFormDisabled}
            />
            <TextFieldComponent
              name="rna"
              label="Rna"
              value={formValues.rna ?? ""}
              onChange={handleInputChange}
              maxLength={255}
              error={!!errors.rna}
              helperText={errors.rna}
              disabled={isFormDisabled}
            />
            <TextFieldComponent
              name="siren"
              label="Siren"
              value={formValues.siren ?? ""}
              onChange={handleInputChange}
              maxLength={255}
              error={!!errors.siren}
              helperText={errors.siren}
              disabled={isFormDisabled}
            />
            <TextFieldComponent
              name="signatoryFirstName"
              label="Signatory First Name"
              value={formValues.signatoryFirstName ?? ""}
              onChange={handleInputChange}
              maxLength={177}
              error={!!errors.signatoryFirstName}
              helperText={errors.signatoryFirstName}
              disabled={isFormDisabled}
            />
            <TextFieldComponent
              name="signatoryLastName"
              label="Signatory Last Name"
              value={formValues.signatoryLastName ?? ""}
              onChange={handleInputChange}
              maxLength={177}
              error={!!errors.signatoryLastName}
              helperText={errors.signatoryLastName}
              disabled={isFormDisabled}
            />
            <SingleSelectComponent
              name="signatoryPosition"
              label="Signatory Position"
              value={formValues.signatoryPosition ?? ""}
              onChange={handleSignatoryPositionChange}
              options={signatoryPositions}
              optionLabelKey="value"
              optionValueKey="code"
              disabled={isFormDisabled}
            />
            <SingleSelectComponent
              name="causeLegalEntityCategories"
              label="Cause Legal Entity Categories"
              value={selectedCauseLegalEntityCategory}
              onChange={handleCauseCategoryChange}
              options={causeLegalEntityCategories}
              optionLabelKey="value"
              optionValueKey="code"
              disabled={isFormDisabled}
            />
            {formValues.causeLegalEntityCategoryCode === "GENERAL_INTEREST" && (
              <SingleSelectComponent
                name="causeLegalEntitySubcategories"
                label="Cause Legal Entity Subcategories"
                value={selectedCauseLegalEntitySubcategory}
                onChange={handleCauseSubcategoryChange}
                options={causeLegalEntitySubcategories}
                optionLabelKey="value"
                optionValueKey="code"
                disabled={isFormDisabled}
              />
            )}
            {formValues.causeLegalEntitySubcategoryCode ===
              "PUBLIC_UTILITY" && (
              <>
                <TextFieldComponent
                  type="date"
                  name="datePublicInterest"
                  label="Date of Public Interest"
                  value={formValues.datePublicInterest ?? ""}
                  onChange={handleInputChange}
                  maxLength={177}
                  error={!!errors.datePublicInterest}
                  helperText={errors.datePublicInterest}
                  disabled={isFormDisabled}
                />

                <TextFieldComponent
                  type="date"
                  name="dateOfficialJournal"
                  label="Date of Official Journal Publication (non mandatory for Moselle)"
                  value={formValues.dateOfficialJournal ?? ""}
                  onChange={handleInputChange}
                  maxLength={177}
                  error={!!errors.dateOfficialJournal}
                  helperText={errors.dateOfficialJournal}
                  disabled={isFormDisabled}
                />
              </>
            )}
            {formValues.causeLegalEntitySubcategoryCode === "OTHER" && (
              <TextFieldComponent
                name="otherSubcategoryValue"
                label="Other Subcategory Value"
                value={formValues.otherSubcategoryValue ?? ""}
                onChange={handleInputChange}
                maxLength={177}
                error={!!errors.otherSubcategoryValue}
                helperText={errors.otherSubcategoryValue}
                disabled={isFormDisabled}
              />
            )}
            {formValues.causeLegalEntityCategoryCode === "HERITAGE_FND" && (
              <TextFieldComponent
                type="date"
                name="dateBudgetMinistryAgreement"
                label="Date of agreement with the budget ministry"
                value={formValues.dateBudgetMinistryAgreement ?? ""}
                onChange={handleInputChange}
                maxLength={177}
                error={!!errors.dateBudgetMinistryAgreement}
                helperText={errors.dateBudgetMinistryAgreement}
                disabled={isFormDisabled}
              />
            )}

            {formValues.causeLegalEntityCategoryCode === "EU_ENTITY" && (
              <TextFieldComponent
                type="date"
                name="dateUeAgreement"
                label="Date of EU agreement"
                value={formValues.dateUeAgreement ?? ""}
                onChange={handleInputChange}
                maxLength={177}
                error={!!errors.dateUeAgreement}
                helperText={errors.dateUeAgreement}
                disabled={isFormDisabled}
              />
            )}
            <Box sx={{ mt: 2 }}>
              <CheckboxComponent
                name="is200Cgi"
                label="200 CGI"
                checked={formValues.is200Cgi!}
                onChange={handleInputChange}
              />
              <CheckboxComponent
                name="is978Cgi"
                label="978 CGI"
                checked={formValues.is978Cgi!}
                onChange={handleInputChange}
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
              onClick={handleSubmit}
              disabled={isFormDisabled}
            >
              Submit
            </Button>

            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity="success"
                sx={{ width: "100%" }}
              >
                The cause has been updated successfully!
              </Alert>
            </Snackbar>
          </>
        )}
        <FormControl error={!!errorMessageSaveData}>
          {errorMessageSaveData && (
            <FormHelperText>{errorMessageSaveData}</FormHelperText>
          )}
        </FormControl>
      </form>
    </Box>
  );
}
export default TaxReceiptUpdate;
